<template>
  <div class="article-footer">
    <div class="footer-inner">
      <template
        v-if="data.secondary_tags && Object.keys(data.secondary_tags).length"
      >
        <h4 class="footer__title" v-if="showTitle">
          Objectifs pédagogiques
        </h4>
        <div class="footer__tags">
          <Tag
            class="footer__tags__tag"
            :data="tag"
            v-for="tag in data.secondary_tags"
            :key="`tag-${tag.label}`"
            modifier="reverse"
          />
        </div>
      </template>

      <div class="footer__action">
        <g-btn
          v-if="data.downloadLink"
          modifier="download"
          :content="{
            label: downloadLabel,
            url: data.downloadLink.url,
            target: '_blank',
          }"
        ></g-btn>

        <!-- <g-btn
          modifier="download"
          :content="{
            label: pdfLabel,
            url: data.downloadLink.url,
            target: '_blank',
          }"
        ></g-btn> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-inner {
  margin: 0 $spacing * 1.35;
  @include mq(m) {
    margin: 0;
  }
}

.footer__tags {
  margin-top: $spacing / 2;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer__tags__tag {
  margin-right: $spacing / 4;
}

.footer__title {
  margin-top: $spacing * 0.85;
  line-height: 1.3em;
  @include mq(m) {
    margin-top: $spacing * 1.2;
  }
}

.btn--download {
  margin-left: 1rem;

  &:first-child {
    margin-left: 0;
  }
}
</style>

<script lang="ts">
import Tag from '@/components/Tag.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: Object,
    showTitle: {
      type: Boolean,
      default: true,
    },
    downloadLabel: {
      type: String,
      default: 'Télécharge les annexes de cette activité',
    },
    pdfLabel: {
      type: String,
      default: 'Télécharger le document',
    },
  },
  components: {
    Tag,
  },
})
</script>
