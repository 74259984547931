
import Tag from '@/components/Tag.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    data: Object,
    showTitle: {
      type: Boolean,
      default: true,
    },
    downloadLabel: {
      type: String,
      default: 'Télécharge les annexes de cette activité',
    },
    pdfLabel: {
      type: String,
      default: 'Télécharger le document',
    },
  },
  components: {
    Tag,
  },
})
