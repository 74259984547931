<template>
  <div class="recipe" v-if="data">
    <BackBtn class="recipe__back" />
    <div class="recipe-inner--large">
      <ArticleHeader :data="data.header" />
      <div class="recipe__content">
        <div v-if="data.body.materials">
          <h4>Matériels</h4>
          <ul>
            <li
              v-for="material in data.body.materials"
              :key="`material-${material.label}`"
            >
              {{ material.label }}
            </li>
          </ul>
        </div>

        <h4>Ingrédients</h4>
        <ul>
          <li
            v-for="ingredient in data.body.ingredients"
            :key="`ingredient-${ingredient.label}`"
          >
            {{ ingredient.label }}
          </li>
        </ul>

        <h4>Préparation</h4>
        <div class="recipe__content__text" v-html="data.body.preparation"></div>
      </div>

      <ArticleFooter
        :data="data.footer"
        :showTitle="false"
        pdfLabel="Télécharger la recette en PDF"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/recipe'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import ArticleFooter from '@/components/article/Footer.vue'
import ArticleHeader from '@/components/article/Header.vue'

export default defineComponent({
  name: 'recipe',
  components: {
    BackBtn,
    ArticleFooter,
    ArticleHeader,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.recipe {
  @include details-intro;

  position: relative;
  background-color: $c-white;
}

[class*='recipe-inner--'],
.recipe-inner {
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  padding: $spacing * 3 0;
  z-index: 0;

  @include mq(s) {
    width: calc(100% - #{$spacing * 4});
  }

  &[class*='--large'] {
    max-width: 60rem;
    @include mq(m) {
      max-width: 72rem;
    }
  }
}

[class*='recipe__content--'],
.recipe__content {
  margin: 0 $spacing * 1.35;

  @include mq(m) {
    margin: 0;
  }
}

.recipe__content h4 {
  margin-top: $spacing * 1.5;
  margin-bottom: $spacing / 4;

  &:first-child {
    margin-top: 0;
  }
}

.recipe__content div {
  margin: 0 0 $spacing * 1.5;
}

.recipe__content ::v-deep(img) {
  margin-bottom: $spacing / 2;
}

.recipe__content ::v-deep(ul) {
  list-style: none;
  padding: 0;
  margin: 0 0 $spacing * 1.5;
}
.recipe__content ::v-deep(li) {
  display: flex;

  & + li {
    margin-top: $spacing / 2;
  }

  &::before {
    content: '\2022';
    color: $regal-blue;
    display: inline-block;
    width: 6px;
    margin-right: $spacing / 2;
  }
}

.recipe__content__text {
  ::v-deep(p) {
    margin-bottom: $spacing / 2;
  }
}
</style>
